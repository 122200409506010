
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import useSchedule from '@/components/schedule/schedule';
import Form from '@/components/form/Form.vue';
import FormItem from '@/components/form/FormItem.vue';
import useSpider from '@/components/spider/spider';
import {TASK_MODE_SELECTED_NODE_TAGS, TASK_MODE_SELECTED_NODES} from '@/constants/task';
import useNode from '@/components/node/node';
import CheckTagGroup from '@/components/tag/CheckTagGroup.vue';
import InputWithButton from '@/components/input/InputWithButton.vue';
import Switch from '@/components/switch/Switch.vue';
import {ElMessage} from 'element-plus';
import ScheduleCron from '@/components/schedule/ScheduleCron.vue';

export default defineComponent({
  name: 'ScheduleForm',
  components: {
    ScheduleCron,
    Switch,
    FormItem,
    Form,
    CheckTagGroup,
    InputWithButton,
  },
  setup() {
    // store
    const ns = 'schedule';
    const store = useStore();

    // use node
    const {
      allListSelectOptions: allNodeSelectOptions,
      allTags: allNodeTags,
    } = useNode(store);

    // use spider
    const {
      allListSelectOptions: allSpiderSelectOptions,
    } = useSpider(store);

    // use schedule
    const {
      form,
    } = useSchedule(store);

    // on enabled change
    const onEnabledChange = async (value: boolean) => {
      if (value) {
        await store.dispatch(`${ns}/enable`, form.value._id);
        ElMessage.success('Enabled successfully');
      } else {
        await store.dispatch(`${ns}/disable`, form.value._id);
        ElMessage.success('Disabled successfully');
      }
      await store.dispatch(`${ns}/getList`);
    };

    return {
      ...useSchedule(store),

      allSpiderSelectOptions,
      allNodeSelectOptions,
      allNodeTags,
      TASK_MODE_SELECTED_NODES,
      TASK_MODE_SELECTED_NODE_TAGS,
      onEnabledChange,
    };
  },
});
