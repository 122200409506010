
import {defineComponent} from 'vue';
import ListLayout from '@/layouts/ListLayout.vue';
import useScheduleList from './scheduleList';
import CreateEditScheduleDialog from '@/components/schedule/CreateEditScheduleDialog.vue';

export default defineComponent({
  name: 'ScheduleList',
  components: {
    ListLayout,
    CreateEditScheduleDialog,
  },
  props: {
    noActions: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
    } = useScheduleList();

    return {
      navActions,
      tableColumns,
      tableData,
      tableTotal,
      tablePagination,
      actionFunctions,
    };
  },
});
