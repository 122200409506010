
import {defineComponent} from 'vue';
import {buttonProps} from '@/components/button/Button.vue';

export const iconButtonProps = {
  icon: {
    type: String,
    required: true,
  },
  ...buttonProps,
};

export default defineComponent({
  name: 'IconButton',
  props: iconButtonProps,
  emits: [
    'click',
  ],
  setup() {
    return {};
  },
});
