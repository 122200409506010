
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import CreateEditDialog from '@/components/dialog/CreateEditDialog.vue';
import ScheduleForm from '@/components/schedule/ScheduleForm.vue';
import useSchedule from '@/components/schedule/schedule';

export default defineComponent({
  name: 'CreateEditScheduleDialog',
  components: {
    CreateEditDialog,
    ScheduleForm,
  },
  setup() {
    // store
    const store = useStore();

    return {
      ...useSchedule(store),
    };
  },
});
