
import {defineComponent, inject, PropType, Ref, ref} from 'vue';
import FormTable from '@/components/form/FormTable.vue';
import {emptyArrayFunc} from '@/utils/func';
import Switch from '@/components/switch/Switch.vue';
import Button from '@/components/button/Button.vue';

export default defineComponent({
  name: 'CreateDialogContentBatch',
  components: {
    Button,
    Switch,
    FormTable,
  },
  props: {
    data: {
      type: Array as PropType<TableData>,
      required: true,
      default: emptyArrayFunc,
    },
    fields: {
      type: Array as PropType<FormTableField[]>,
      required: true,
      default: emptyArrayFunc,
    }
  },
  setup(props: CreateDialogContentBatchProps) {
    const editAll = ref<boolean>(false);

    const actionFunctions = inject('action-functions') as CreateEditDialogActionFunctions;

    const onAdd = (rowIndex: number) => {
      actionFunctions?.onAdd?.(rowIndex);
    };

    const onClone = (rowIndex: number) => {
      actionFunctions?.onClone?.(rowIndex);
    };

    const onDelete = (rowIndex: number) => {
      actionFunctions?.onDelete?.(rowIndex);
    };

    const onFieldChange = (rowIndex: number, prop: string, value: any) => {
      if (editAll.value) {
        // edit all rows
        rowIndex = -1;
      }

      actionFunctions?.onFieldChange?.(rowIndex, prop, value);
    };

    const onFieldRegister = (rowIndex: number, prop: string, formRef: Ref) => {
      actionFunctions?.onFieldRegister(rowIndex, prop, formRef);
    };

    return {
      editAll,
      onAdd,
      onClone,
      onDelete,
      onFieldChange,
      onFieldRegister,
    };
  },
});
